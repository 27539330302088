.addPersonForm {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
}

.addPersonForm > * {
  margin-top: 8px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
