.stickyMenu {
  width: 100%;
}

.default-button {
  text-transform: none !important;
}

.selected {
  color: #01aa25 !important;
}
