.nameField {
  overflow: hidden;
  text-overflow: ellipsis;
}

.thReview {
  font-size: 0.75em !important;
  padding: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: 1px solid #eee !important;
}

.tdReview {
  padding: 0px !important;
  border: 1px solid #eee !important;
  word-wrap: break-word;
}
