.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s ease-in-out;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  margin: 5% auto;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  transition: inherit;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100px);
  display: flex;
  flex-direction: column;
}

.modal.active .modal__content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.modal__body,
.modal__footer {
  padding: 8px;
}

.modal__header {
  padding: 0px 8px 0px 8px;
}

.modal__body {
  overflow: auto !important;
}

.modal__close {
  margin-left: auto;
  margin-right: 8px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #7f7f7f;
}
.modal__close:hover {
  color: #000;
}
