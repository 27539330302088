.btn {
  width: 120px;
  background-color: #01aa25;
  color: white;
  font-family: 'Inter';
  height: 30px;
}

.btn-submit {
  width: 120px;
  background-color: #01aa25;
  border: 1px solid #01aa25 !important;
  color: white;
  font-family: 'Inter';
  height: 30px;
  border-radius: 4px;
}

.title-text {
  font-size: 16px !important;
  color: black !important;
  font-family: 'Inter-Bold' !important;
}

.close {
  color: black !important;
  font-size: 25px !important;
}

.popup-container {
  min-height: 150px;
  max-width: 300px;
  display: flex;
  row-gap: 20px;
}

.text {
  font-family: 'Inter' !important;
  font-size: 12px !important;
}

.popup-noreports {
  height: auto;
  max-width: 300px;
}
