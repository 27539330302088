.btn {
  border: 1px solid black;
  border-radius: 6px;
  width: auto;
  text-align: center;
  font-size: 15px !important;
  font-family: 'Inter-Bold' !important;
}

.swal2-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  column-gap: 10px;
  padding: 0 10px;
}

.confirm {
  background-color: white;
  color: black !important;
  padding: 5px 5px;
}

.cancel {
  background-color: black;
  color: white;
  padding: 5px 15px;
}

.text-container {
  font-size: 15px !important;
  font-family: 'Inter' !important;
}

.text-title {
  font-size: 20px !important;
  color: black !important;
  font-family: 'Inter-Bold' !important;
}

.Toastify__toast--error {
  background: #000000 !important;
}

.Toastify__toast-body {
  padding-left: 15px !important;
}

.Toastify__toast {
  width: 100vw !important;
  padding: 2px !important;
}

.Toastify__toast-body {
  font-size: 14px !important;
}

:root {
  --toastify-toast-min-height: 34.5px !important;
  --toastify-text-color-light: #fff !important;
  --toastify-font-family: 'Inter' !important;
}
