.stickyHeader {
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  inset-block-start: 0;
  z-index: 10;
  background-color: white;
}

.stickyHeaderUpper {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 100%;
  align-items: center;
  justify-content: center;
  inset-block-start: 0;
  background-color: #01ac25;
}

.stickyHeaderMid {
  width: 100%;
  background-color: #01ac25;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  text-align: center;
}

.dateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stickyRight {
  display: flex;
  flex-basis: 0;
  min-width: -webkit-min-content;
}

.stickyRight {
  justify-content: center;
}
