.dialogBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 4rem;
  /* TODO Dan to fix
  position: absolute;
  height: calc(100% - 70px); 
  */
}

#wrapper {
  padding-top: 56.25%;
  display: flex;
}

.formatForm {
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
}

@media screen and (max-width: 600px) {
  .dialogBody {
    flex-direction: column;
  }
}
