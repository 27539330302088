.btn {
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  font-size: 15px !important;
  font-family: 'Inter-Bold' !important;
}

.confirm-order {
  background-color: white !important;
  color: black !important;
  padding: 5px 40px;
  width: auto !important;
}

.cancel-order {
  background-color: black !important;
  color: white;
  padding: 5px 40px;
  width: auto !important;
}

.text-container {
  font-size: 15px !important;
  font-family: 'Inter' !important;
}

.text-title {
  font-size: 20px !important;
  color: black !important;
  font-family: 'Inter-Bold' !important;
}

.Toastify__toast--error {
  background: #000000 !important;
}

.swal2-container {
  padding: 0 20px;
}

.swal2-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
