.tdSelect > .MuiSelect-select {
  padding: 8px 32px 8px 8px !important;
}

.tdSelect .tdSelect {
  padding: 2px !important;
}

.table {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-collapse: separate !important;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.table .tr {
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  padding: 0.35em;
}

.table .th,
.table .td {
  padding: 3px 12px 3px 12px;
  text-align: center;
}

.table th:first-child,
.table td:first-child {
  border-left: 1px solid #ccc;
  border-right: 1px solid #f3f3f3;
}

.table .th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.tableOptions {
  margin: auto;
}

.tableOptions div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tableOptions div * {
  margin: 2px;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.actionsContainer > button {
  white-space: normal;
  max-width: 100px;
  margin: 0px 8px 0px 8px;
}

.tableCaption {
  text-align: center !important;
  background-color: #f8f8f8;
}

.thSticky {
  position: sticky;
  left: 0;
  z-index: 3 !important;
}

.tdSticky {
  position: sticky;
  left: 0;
  z-index: 3 !important;
  border: 1px;
  background-color: #f8f8f8;
}

.viewButtonSelected {
  border-radius: 0px !important;
  border-bottom: 4px solid #40c365 !important;
}

/* 
  Grid view style
*/

.gridView,
.gridView .table {
  max-width: 400px;
  border: 0;
  margin: auto;
}

.gridView .table .thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  font-size: 1em;
}

.gridView .table .tr {
  border-bottom: none;
  display: block;
  margin-bottom: 0.625em;
  border: 0;
  background-color: transparent;
}

.gridView .table .td {
  border-bottom: none;
  display: block;
  text-align: center;
}

.gridView .table .td::before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
  text-transform: uppercase;
}

.gridView .table .td:last-child {
  border-bottom: 0;
}

.gridView .actionsContainer {
  flex-direction: row;
  justify-content: space-between;
}

.gridView .table td:first-child {
  border: 0;
  font-size: 1.25em;
  background-color: transparent;
}

.gridView .actionsContainer > button {
  max-width: 150px;

  margin: 0px;
}
