.managementOptionsContainer .modal__content {
  max-height: 80% !important;
  max-width: 800px !important;
}

.memberRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ddd;
}

.memberGrid > * {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.memberName {
  text-align: center;
  overflow: hidden;
}

.memberName p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.actions {
  display: flex;
}

.addPersonButton {
  float: right;
  right: 25px;
}

.modalButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modalButtonContainer > * {
  margin: 16px !important;
}
