#forgotPass {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 15px;
  color: #8a8a8a;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  cursor: pointer;
}

#forgotPass:hover {
  color: #1976d2;
}

.swal2-container {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
