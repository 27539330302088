.swal2-container {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.stickyHeader {
  position: sticky;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  top: 0;
  inset-block-start: 0;
  background-color: white;
  z-index: 5;
  padding-bottom: 8px;
}

.dateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.departmentsMembersContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.membersContainer > button {
  width: auto;
  margin: 0px 8px 0px 8px;
}

.notesContainer {
  padding-top: 8px;
}

.communityName {
  margin-bottom: 0px !important;
}

.dateLink {
  color: #166f3a;
  cursor: pointer;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.stickyLeft,
.stickyRight {
  flex: 1;
  display: flex;
  flex-basis: 0;
  min-width: -webkit-min-content;
}

.stickyRight {
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .submitButton {
    position: relative !important;
    flex-direction: row;
    top: 0px;
    right: 0px;
    color: white !important;
  }

  .dateLink {
    font-size: 1rem;
  }
}
