.spinner {
  z-index: 9999999;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cssload-fond {
  position: relative;
  margin: auto;
}

.cssload-container-general {
  animation: cssload-animball_two 2.5s infinite;
  -o-animation: cssload-animball_two 2.5s infinite;
  -ms-animation: cssload-animball_two 2.5s infinite;
  -webkit-animation: cssload-animball_two 2.5s infinite;
  -moz-animation: cssload-animball_two 2.5s infinite;
  width: 116px;
  height: 116px;
}
.cssload-internal {
  width: 116px;
  height: 116px;
  position: absolute;
}
.cssload-ballcolor {
  width: 53px;
  height: 53px;
  border-radius: 50%;
}
.cssload-ball_1,
.cssload-ball_2,
.cssload-ball_3,
.cssload-ball_4 {
  position: absolute;
  animation: cssload-animball_one 2.5s infinite ease;
  -o-animation: cssload-animball_one 2.5s infinite ease;
  -ms-animation: cssload-animball_one 2.5s infinite ease;
  -webkit-animation: cssload-animball_one 2.5s infinite ease;
  -moz-animation: cssload-animball_one 2.5s infinite ease;
}
.cssload-ball_1 {
  background-color: rgb(255, 198, 26);
  top: 0;
  left: 0;
}
.cssload-ball_2 {
  background-color: rgb(227, 41, 44);
  top: 0;
  left: 63px;
}
.cssload-ball_3 {
  background-color: rgb(44, 161, 220);
  top: 63px;
  left: 0;
}
.cssload-ball_4 {
  background-color: rgb(11, 194, 99);
  top: 63px;
  left: 63px;
}

@keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 32px;
    left: 32px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-o-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 32px;
    left: 32px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-ms-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 32px;
    left: 32px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-webkit-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 32px;
    left: 32px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-moz-keyframes cssload-animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 32px;
    left: 32px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@keyframes cssload-animball_two {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}

@-o-keyframes cssload-animball_two {
  0% {
    -o-transform: rotate(0deg) scale(1);
  }
  50% {
    -o-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -o-transform: rotate(720deg) scale(1);
  }
}

@-ms-keyframes cssload-animball_two {
  0% {
    -ms-transform: rotate(0deg) scale(1);
  }
  50% {
    -ms-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -ms-transform: rotate(720deg) scale(1);
  }
}

@-webkit-keyframes cssload-animball_two {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(1);
  }
}

@-moz-keyframes cssload-animball_two {
  0% {
    -moz-transform: rotate(0deg) scale(1);
  }
  50% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -moz-transform: rotate(720deg) scale(1);
  }
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
